import { useMutation } from "react-query"
import { instance } from "../instance"


interface IRegisterPayload{
    email:string,
first_name:string,
last_name:string,
phone_number:string
}
export const useRegistration=()=>{
    const register=async(payload:IRegisterPayload)=>{
        const {data}= await instance.post(`/user/register-wait-list`, payload)
        return data
    }
    const mutate= useMutation(register)
    return mutate
}