import { Field, Form, Formik } from 'formik'
import React, { useState } from 'react'
import image1 from "../../assets/images/image1.png"
import image2 from "../../assets/images/image2.png"
import image3 from "../../assets/images/image3.png"
import image4 from "../../assets/images/image4.png"
import image5 from "../../assets/images/image5.png"
import image6 from "../../assets/images/image6.png"
import image7 from "../../assets/images/image7.png"
import image9 from "../../assets/images/image9.png"
import image10 from "../../assets/images/image10.png"
import image11 from "../../assets/images/image11.png"
import image12 from "../../assets/images/image12.png"
import image13 from "../../assets/images/image13.png"
import image8 from "../../assets/images/image8.png"
import stemLogo from "../../assets/images/stem-logo.png"
import { useRegistration } from '../../hooks/landingpage/useLandingPage'
import { toast } from 'react-toastify'
import ThankYou, { ExistingCustomer } from './components/ThankYou'
function LandingPage() {
    // const colors=[ "blue", "yellow", "pink", "cyan", "black", "red"]
 const registerAPI= useRegistration()
 const [activeState, setActiveState]=useState<""|"success"|"failed">("")
    return (
    <div className=''>

<div className="h-24 pl-3 md:pl-6">
    <img src={stemLogo} alt="" className='h-24 p-4' />
</div>

      <div className="grid grid-cols-4 md:grid-cols-8 h-36 overflow-hidden">
        <img src={image1} alt="" className='w-full h-36 ' />
        <img src={image2} alt="" className='w-full h-36 ' />
        <img src={image3} alt="" className='w-full h-36 ' />
        <img src={image4} alt="" className='w-full h-36 ' />
        <img src={image5} alt="" className='w-full h-36 ' />
        <img src={image6} alt="" className='w-full h-36 ' />
        <img src={image7} alt="" className='w-full h-36 ' />
        <img src={image8} alt="" className='w-full h-36 ' />
        {/* {colors.map(item=><div className={`w-full h-24  bg-[${item}]`} style={{background:item}}></div>)} */}
      </div>

      <div className="grid lg:grid-cols-[322px_minmax(600px,_1fr)_322px]">
<div className="hidden lg:grid lg:grid-cols-2">
        <img src={image9} alt="" className='w-full h-36 ' />
        <img src={image7} alt="" className='w-full h-36 ' />
        <img src={image10} alt="" className='w-full h-36 ' />
        <img src={image12} alt="" className='w-full h-36 ' />
        <img src={image11} alt="" className='w-full h-36 ' />
        <img src={image13} alt="" className='w-full h-36 ' />
        {/* {colors.slice(0,6).map(item=><div className={`w-full h-36  bg-[${item}]`} style={{background:item}}></div>)} */}

</div>

<div className={`w-full   `}>
<div className={`w-full h-72 `}>
{/* <video
 src="https://www.youtube.com/embed/BLBRRNwMZNE?si=gbv6ASggWALSwtK_" 
 width="320" height="240" controls className='w-full' autoPlay /> */}
{activeState==="success"?<ThankYou/>:activeState==="failed"?<ExistingCustomer/>:<iframe 
width="320"
 height="240" 
className='w-full h-72'
src="https://www.youtube.com/embed/BLBRRNwMZNE?si=gbv6ASggWALSwtK_" 
title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
 ></iframe>}
</div>

<div className="bg-green-400 p-8 md:p-10 text-white">
<h2 className='text-2xl'>Secure your spot</h2>
<p className=' text-lg'>Join the waitlist for stem africa fest 2025</p>
</div>
</div>


{activeState===""?
<div className="p-4">
<Formik initialValues={{
email:"",
first_name:'',
last_name:"",
phone_number:""
}}
onSubmit={(values, {setSubmitting})=>{
setSubmitting(true)
 registerAPI.mutate(values,{
    onSuccess(response){
toast.dismiss()
toast.success(`Kindly check your mail for next step`)
   
setActiveState("success")},
    onError(err:any){
        toast.dismiss()
        toast.error(err.response?.data?.message)

setActiveState("failed")
    },
    onSettled(){
        setSubmitting(false)
    }
})
}}
>
  {({isSubmitting})=>  <Form>
        <div className=" mb-2">
            <p className='mb-1 font-semibold text-black'>First name </p>
            <Field name="first_name" required className="w-full input input-bordered bg-white text-black"/>
        </div>
        <div className="mb-2 ">
            <p className='mb-1 font-semibold text-black'>Last name </p>
            <Field name="last_name" className="w-full input input-bordered bg-white text-black"/>
        </div>
        <div className="mb-2 ">
            <p className='mb-1 font-semibold text-black'>Phone Number </p>
            <Field name="phone_number" required className="w-full input input-bordered bg-white text-black"/>
        </div>
        <div className="mb-2 ">
            <p className='mb-1 font-semibold text-black'>Email</p>
            <Field name="email" type="email" required className="w-full input input-bordered bg-white text-black"/>
        </div>
<div className="px-2">
    <button className='btn btn-secondary text-white btn-block' disabled={isSubmitting}>Click to submit</button>
</div>

    </Form>}
</Formik></div>
:<div className="hidden lg:grid lg:grid-cols-2">
        <img src={image9} alt="" className='w-full h-36 ' />
        <img src={image7} alt="" className='w-full h-36 ' />
        <img src={image10} alt="" className='w-full h-36 ' />
        <img src={image12} alt="" className='w-full h-36 ' />
        <img src={image11} alt="" className='w-full h-36 ' />
        <img src={image13} alt="" className='w-full h-36 ' />
        {/* {colors.slice(0,6).map(item=><div className={`w-full h-36  bg-[${item}]`} style={{background:item}}></div>)} */}

</div>}

      </div>
    </div>
  )
}

export default LandingPage
