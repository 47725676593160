import React, { ReactNode } from 'react';
import logo from './logo.svg';
import './App.css';
import LandingPage from './pages/landingpage/LandingPage';

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App() {
  return (
   <div className="">
    <Layout>

    <LandingPage/>
    </Layout>
    <ToastContainer/>
   </div>
  );
}

export default App;
interface ILayout{
  children:ReactNode
}
const Layout=({children}:ILayout):JSX.Element=>{
return <div className="bg-white flex flex-col items-center justify-center min-h-[100vh] w-full">
<div className=" max-w-screen-xl">

{children}
</div>
</div>
}