import React from 'react'
import confetti from 'canvas-confetti'
function ThankYou() {
    React.useEffect(() => {
        confetti({
          particleCount: 100,
          spread: 70,
          origin: { y: 0.6 }
        });
      }, []);
  return (
    <div className='flex flex-col items-center justify-center p-4 h-72 bg-red-300 text-white'>
      <h2 className='text-2xl font-extrabold mb-4'>Hurray.</h2>
      <h2 className='text-2xl font-extrabold mb-4'>Thank You for joining the waitlist.</h2>
      <h4 className="font-semibold">Kindly Check your mail for the next step to take </h4>
    </div>
  )
}

export function ExistingCustomer(){
  return  <div className='flex flex-col items-center justify-center p-4 h-72 bg-red-700 text-white'>
    <h2 className='text-2xl font-extrabold mb-4'>Oops</h2>
    <h2 className='text-2xl font-extrabold mb-4'>Sorry You are already in the waitlist before</h2>
    <h4 className="font-semibold">Kindly Check your mail for the next step to take </h4>
  </div>
}

export default ThankYou
